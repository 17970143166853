import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import CompareArrows from "@mui/icons-material/CompareArrows";
import DateRange from "@mui/icons-material/DateRange";
import Delete from "@mui/icons-material/Delete";
import Event from "@mui/icons-material/Event";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import QueryBuilder from "@mui/icons-material/QueryBuilder";
import Select from "@mui/material/Select";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import {
  InputLabel,
  ListItemText,
  CardHeader,
  Grid,
  FormControl,
  TextField,
} from "@mui/material";
import { getDay } from "date-fns";
import { useNavigate } from "react-router-dom";

import { Schedule, ScheduleFrequency, User } from "../../../types";
import { hours, endHours, weekdays } from "../../../constants";
import { AttachMoney } from "@mui/icons-material";

const durations = [
  1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
  10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15, 15.5, 16, 16.5,
];

interface AssignedCleanerCardProps {
  employees: User[];
  onChange: (property: string, value: string | string[]) => void;
  onDelete: () => void;
  schedule: Omit<Schedule, "timestamp">;
}

function AssignedCleanerCard({
  employees,
  onChange,
  onDelete,
  schedule,
}: AssignedCleanerCardProps) {
  const navigate = useNavigate();
  const validDayIndex = getDay(new Date()) - 1;

  return (
    <Card className={`mvs ${schedule.deleted ? "card-disabled" : ""}`}>
      <CardContent>
        <CardHeader
          action={
            <>
              <IconButton
                onClick={() =>
                  navigate(`/employees/${schedule.employee}/reassignShifts`)
                }
              >
                <CompareArrows />
              </IconButton>
              <IconButton onClick={() => onDelete()}>
                <Delete />
              </IconButton>
            </>
          }
        />
        <Grid container={true} spacing={2}>
          <Grid item={true} md={12} className="df fww">
            <div className="df aic">
              <SupervisorAccount className="mrs" />
              <div className="mrm">
                <FormControl sx={{ minWidth: 124 }}>
                  <InputLabel id="cleaner">Cleaner</InputLabel>
                  <Select
                    label="Cleaner"
                    labelId="cleaner"
                    value={schedule.employee}
                    onChange={(e) => onChange("employee", e.target.value)}
                  >
                    {React.Children.toArray(
                      employees.map((employee) => (
                        <MenuItem value={employee.id}>
                          <ListItemText
                            primary={`${employee.firstName} ${employee.lastName}`}
                          />
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="df aic">
              <QueryBuilder className="mrs" />
              <div className="mrm">
                <FormControl sx={{ minWidth: 124 }}>
                  <InputLabel id="startTime">Start Time</InputLabel>
                  <Select
                    label="Start Time"
                    labelId="startTime"
                    value={schedule.start}
                    onChange={(e) => onChange("start", e.target.value)}
                  >
                    {React.Children.toArray(
                      hours.map((hour) => (
                        <MenuItem value={hour}>
                          <ListItemText primary={hour} />
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="mrm">
                <FormControl sx={{ minWidth: 124 }}>
                  <InputLabel id="endTime">End Time</InputLabel>
                  <Select
                    label="End Time"
                    labelId="endTime"
                    value={schedule.end}
                    onChange={(e) => onChange("end", e.target.value)}
                  >
                    {React.Children.toArray(
                      endHours.map((hour) => (
                        <MenuItem value={hour}>
                          <ListItemText primary={hour} />
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="df aic">
              <Event className="mrs" />
              <div className="mrm">
                <FormControl sx={{ minWidth: 124 }}>
                  <InputLabel id="duration">Duration</InputLabel>
                  <Select
                    label="Duration"
                    labelId="duration"
                    value={schedule.duration}
                    onChange={(e) => onChange("duration", e.target.value)}
                  >
                    {React.Children.toArray(
                      durations.map((duration) => (
                        <MenuItem value={`${duration} hr`}>
                          <ListItemText primary={`${duration} hr`} />
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </div>
              <div className="mrm">
                <FormControl sx={{ minWidth: 124 }}>
                  <InputLabel id="frequency">Frequency</InputLabel>
                  <Select
                    label="Frequency"
                    labelId="frequency"
                    value={schedule.frequency}
                    onChange={(e) => onChange("frequency", e.target.value)}
                  >
                    <MenuItem value={ScheduleFrequency.Weekly}>
                      <ListItemText primary="Weekly" />
                    </MenuItem>
                    <MenuItem value={ScheduleFrequency.BiWeekly}>
                      <ListItemText primary="Bi-Weekly" />
                    </MenuItem>
                    <MenuItem value={ScheduleFrequency.SingleWeek}>
                      <ListItemText primary="This Week" />
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>
          </Grid>
          <Grid item={true} md={12} className="df">
            <DateRange className="mrs" />
            <div>
              <InputLabel>Days of the week</InputLabel>
              <div className="df aic fww">
                {React.Children.toArray(
                  weekdays.map((weekday, index) => (
                    <div className="df aic">
                      <InputLabel>{weekday}</InputLabel>
                      <Checkbox
                        disabled={
                          schedule.frequency === ScheduleFrequency.SingleWeek &&
                          validDayIndex >= index
                        }
                        checked={schedule.weekdays.some(
                          (day) => day === weekday
                        )}
                        onChange={(e, checked) =>
                          onChange(
                            "weekdays",
                            checked
                              ? [weekday, ...schedule.weekdays]
                              : schedule.weekdays.filter(
                                  (day) => day !== weekday
                                )
                          )
                        }
                        className="checkbox--small-padding"
                        color="primary"
                      />
                    </div>
                  ))
                )}
              </div>
            </div>
          </Grid>
          <Grid item={true} md={12} className="df aic">
            <AttachMoney className="mrs" />
            <TextField
              label="Flat Rate"
              value={schedule.flatRate || ""}
              name="flatRate"
              onChange={(e) => onChange(e.target.name, e.target.value)}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AssignedCleanerCard;

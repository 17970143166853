import {
  doc,
  FieldPath,
  serverTimestamp,
  WhereFilterOp,
  writeBatch,
} from "firebase/firestore";
import { collection, getDocs, query, where } from "firebase/firestore";
import { Shift } from "types";

import { db } from "../firebaseconfig";

const shiftsService = {
  getByQuery(
    fieldPath: string | FieldPath,
    operation: WhereFilterOp,
    value: any
  ) {
    return getDocs(
      query(collection(db, "shifts"), where(fieldPath, operation, value))
    );
  },
  async update(fillInShifts: Shift[]) {
    let batch = writeBatch(db);
    fillInShifts.forEach((fillInShift) => {
      const { id, ...shift } = fillInShift;
      const timestamped = {
        ...shift,
        timestamp: serverTimestamp(),
      };
      // If an existing shift
      if (id) {
        batch.update(doc(db, "shifts", id), timestamped);
      } else {
        const shiftsId = doc(collection(db, "shifts")).id;
        batch.set(doc(db, "shifts", shiftsId), timestamped);
      }
    });
    return await batch.commit();
  },
};

export default shiftsService;

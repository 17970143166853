import { Action } from "redux";

import { ThunkResult, Location } from "../types";
import { locationService } from "../services";

export interface LocationAction extends Action {
  type: LocationActionType;
  payload?: Location[];
}

export enum LocationActionType {
  DELETE_LOCATION_ERROR = "DELETE_LOCATION_ERROR",
  DELETE_LOCATION_LOADING = "DELETE_LOCATION_LOADING",
  DELETE_LOCATION_RESET = "DELETE_LOCATION_RESET",
  DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS",
  GET_LOCATION_ERROR = "GET_LOCATION_ERROR",
  GET_LOCATION_LOADING = "GET_LOCATION_LOADING",
  GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS",
}

export const deleteLocation = (id: string): ThunkResult<LocationAction> => {
  return async (dispatch) => {
    dispatch(deleteLocationLoading());
    locationService.delete(id).then(() => dispatch(deleteLocationSuccess()));
  };
};

export const deleteLocationSuccess = () => ({
  type: LocationActionType.DELETE_LOCATION_SUCCESS,
});

export const deleteLocationReset = () => ({
  type: LocationActionType.DELETE_LOCATION_RESET,
});

export const deleteLocationError = () => ({
  type: LocationActionType.DELETE_LOCATION_ERROR,
});

export const deleteLocationLoading = () => ({
  type: LocationActionType.DELETE_LOCATION_LOADING,
});

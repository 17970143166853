import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

import { Address, Contacts, Supervisor } from "../../types";
import styles from "./AddNewLocationForm.module.scss";
import formatPhoneNumber from "utils/formatPhoneNumber";
import { Status } from "types/status";
import { locationService } from "services";
import { CircularProgress } from "@mui/material";

interface AddNewLocationFormProps {
  open: boolean;
  onClose: () => void;
}

interface State {
  assignedId: string;
  address: Address;
  contact: Contacts;
  name: string;
  supervisor: Supervisor;
}

const initialState: State = {
  assignedId: "",
  address: {
    city: "",
    line1: "",
    line2: "",
    phone: "",
    postal: "",
    state: "",
  },
  contact: {
    primary: {
      email: "",
      name: "",
      phone: "",
    },
    secondary: {
      email: "",
      name: "",
      phone: "",
    },
  },
  name: "",
  supervisor: {
    name: "",
    phone: "",
  },
};

function AddNewLocationForm({ open, onClose }: AddNewLocationFormProps) {
  const [submissionStatus, setSubmissionStatus] = React.useState(Status.Idle);
  const [state, setState] = React.useState(initialState);

  /** Handles address changes */
  function handleAddressChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      address: {
        ...state.address,
        [name]: value,
      },
    });
  }

  /** Handles supervisor changes */
  function handleSupervisorChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      supervisor: {
        ...state.supervisor,
        [name]: value,
      },
    });
  }

  /** Handles primary contact changes */
  function handlePrimaryContactChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      contact: {
        ...state.contact,
        primary: {
          ...state.contact.primary!,
          [name]: value,
        },
      },
    });
  }

  /** Handles secondary contact changes */
  function handleSecondaryContactChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      contact: {
        ...state.contact,
        secondary: {
          ...state.contact.secondary!,
          [name]: value,
        },
      },
    });
  }

  /** Formats phone number */
  function handlePhoneBlur(
    phone: string,
    key: "contact" | "address" | "supervisor",
    keyProp?: "primary" | "secondary"
  ) {
    if (keyProp) {
      setState({
        ...state,
        [key]: {
          ...state[key],
          [keyProp]: {
            ...state[key as "contact"][keyProp],
            phone: formatPhoneNumber(phone),
          },
        },
      });
    } else {
      setState({
        ...state,
        [key]: {
          ...state[key],
          phone: formatPhoneNumber(phone),
        },
      });
    }
  }

  /** Handles input changes */
  function handleLocationChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      [name]: value,
    });
  }

  /** Handles saving */
  async function handleSave() {
    setSubmissionStatus(Status.Pending);
    try {
      // @ts-expect-error Need to fix this type
      await locationService.create(state);
      setSubmissionStatus(Status.Success);
      handleClose();
    } catch (error) {
      console.log(error);
      setSubmissionStatus(Status.Error);
    }
  }

  /** handles toggle visibility */
  function handleClose() {
    setState(initialState);
    onClose();
  }

  const { address, supervisor, contact } = state;

  if (!open) {
    return null;
  }
  return (
    <Dialog open={true}>
      <DialogTitle>Add New Location</DialogTitle>
      <DialogContent>
        <div className="df">
          <div className="mrs">
            <TextField
              margin="dense"
              label="Name"
              name="name"
              className={styles.name}
              onChange={handleLocationChange}
              value={state.name}
            />
          </div>
          <div>
            <TextField
              margin="dense"
              label="Id"
              name="assignedId"
              onChange={handleLocationChange}
              value={state.assignedId}
            />
          </div>
        </div>
        <div className="mvm df">
          <div className="mrs">
            <TextField
              label="Address Line 1"
              className={styles.field}
              name="line1"
              onChange={handleAddressChange}
              value={address.line1}
            />
          </div>
          <div>
            <TextField
              label="Address Line 2"
              className={styles.field}
              name="line2"
              onChange={handleAddressChange}
              value={address.line2}
            />
          </div>
        </div>
        <div className="df mbm">
          <div className="mrs">
            <TextField
              label="City"
              className={styles.field}
              name="city"
              onChange={handleAddressChange}
              value={address.city}
            />
          </div>
          <div className="mrs">
            <TextField
              label="State"
              name="state"
              className={styles.state}
              onChange={handleAddressChange}
              value={address.state}
              fullWidth={true}
            />
          </div>
          <div>
            <TextField
              label="Zip Code"
              name="postal"
              className={styles.postal}
              onChange={handleAddressChange}
              value={address.postal}
              fullWidth={true}
            />
          </div>
        </div>
        <TextField
          label="Phone"
          name="phone"
          className={styles.phone}
          onChange={handleAddressChange}
          value={address.phone}
          onBlur={(e) => handlePhoneBlur(e.target.value, "address")}
          fullWidth={true}
        />
        <div className="df mvm">
          <div className="mrs">
            <TextField
              label="Supervisor"
              className={styles.name}
              name="name"
              onChange={handleSupervisorChange}
              value={supervisor.name}
            />
          </div>
          <div className="mrs">
            <TextField
              label="Contact Phone"
              value={supervisor.phone}
              name="phone"
              className={styles.field}
              onChange={handleSupervisorChange}
              onBlur={(e) => handlePhoneBlur(e.target.value, "supervisor")}
            />
          </div>
        </div>
        <div className="df mbm">
          <div className="mrs">
            <TextField
              label="Contact Name (Primary)"
              value={contact.primary?.name}
              className={styles.name}
              name="name"
              onChange={handlePrimaryContactChange}
            />
          </div>
          <div className="mrs">
            <TextField
              label="Contact Phone"
              value={contact.primary?.phone}
              name="phone"
              className={styles.field}
              onChange={handlePrimaryContactChange}
              onBlur={(e) =>
                handlePhoneBlur(e.target.value, "contact", "primary")
              }
            />
          </div>
          <div>
            <TextField
              label="Contact Email"
              value={contact.primary?.email}
              name="email"
              onChange={handlePrimaryContactChange}
            />
          </div>
        </div>
        <div className="df">
          <div className="mrs">
            <TextField
              label="Contact Name (Secondary)"
              value={contact.secondary?.name}
              className={styles.name}
              name="name"
              onChange={handleSecondaryContactChange}
            />
          </div>
          <div className="mrs">
            <TextField
              label="Contact Phone"
              value={contact.secondary?.phone}
              name="phone"
              className={styles.field}
              onChange={handleSecondaryContactChange}
              onBlur={(e) =>
                handlePhoneBlur(e.target.value, "contact", "secondary")
              }
            />
          </div>
          <div>
            <TextField
              label="Contact Email"
              value={contact.secondary?.email}
              name="email"
              onChange={handleSecondaryContactChange}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={handleSave}
          disabled={
            !state.assignedId ||
            !state.name ||
            !address.line1 ||
            !address.city ||
            !address.state ||
            !address.postal ||
            submissionStatus === Status.Pending
          }
          startIcon={
            submissionStatus === Status.Pending ? (
              <CircularProgress color="secondary" size={16} />
            ) : undefined
          }
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddNewLocationForm;

/** formats a phone number string */
function formatPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) {
    return "";
  }
  const strippedPhoneNumber = phoneNumber.replace(/-/g, "");
  let areaCode = strippedPhoneNumber.substring(0, 3);
  let middle = strippedPhoneNumber.substring(3, 6);
  let end = strippedPhoneNumber.substring(6, 10);
  return `${areaCode}-${middle}-${end}`;
}

export default formatPhoneNumber;

import * as React from "react";
import Add from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { ClassNames } from "@emotion/react";
import { Fab } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getEmployeeLocationsList,
  sortAlpha,
} from "../../utils";
import { addUser } from "../../actions";
import { User, Schedule, GlobalState } from "../../types";
import { useEmployees } from "hooks/useEmployees";
import { GeneralReducerActionType } from "reducers";
import { useLocations } from "hooks/useLocations";
import formatPhoneNumber from "utils/formatPhoneNumber";

const newEmployeeDefaults = {
  admin: false,
  email: "",
  firstName: "",
  inspector: false,
  lastName: "",
  newMessages: [],
  password: "",
  phone: "",
  pin: "",
  userName: "",
};

function Employees() {
  const dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  const [addingEmployee, setAddingEmployee] = React.useState(false);
  const [newEmployee, setNewEmployee] =
    React.useState<User>(newEmployeeDefaults);
  const employees = useEmployees();
  const locations = useLocations();
  const schedules = useSelector<GlobalState, Schedule[]>(
    (state) => state.schedules.schedules.data
  );
  const employeesLocations = React.useMemo(
    () => getEmployeeLocationsList(schedules),
    [schedules]
  );

  /** Filters the employees list by location or employee name */
  const filteredEmployees = React.useMemo(() => {
    if (search) {
      // Filter based on input selections
      return employees.data.filter((employee) => {
        const lowerCaseSearch = search && search.toLowerCase();
        const employeeShiftLocations = getEmployeeLocations(employee.id!);
        const locationMatch = employeeShiftLocations.some((location) =>
          location.toLowerCase().includes(lowerCaseSearch)
        );
        const employeeMatch = `${employee.firstName}${employee.lastName}`
          .toLowerCase()
          .includes(lowerCaseSearch);

        if (locationMatch || employeeMatch) {
          return true;
        }
        return false;
      });
    }

    return employees.data;
  }, [search, employees.data, schedules]);

  /** Gets the english named location for the given employee */
  function getEmployeeLocations(id: string) {
    const employeeLocations = employeesLocations[id];
    if (employeeLocations) {
      return employeeLocations
        .map(
          (employeeLocation) =>
            locations.data.find((location) => location.id === employeeLocation)!
              .name
        )
        .sort(sortAlpha);
    }
    return [];
  }

  /** Renders the employee list */
  function renderEmployee(employee: User) {
    let employeeLocations = getEmployeeLocations(employee.id!);
    return (
      <div key={employee.id}>
        <ListItem button={true}>
          <Link to={`/employees/${employee.id}`}>
            <Typography variant="subtitle1" gutterBottom={true}>
              {`${employee.firstName} ${employee.lastName}`}
            </Typography>
            <Typography variant="caption" gutterBottom={true}>
              {employeeLocations.join(" | ")}
            </Typography>
          </Link>
        </ListItem>
        <Divider key={employee.id + employee.lastName} />
      </div>
    );
  }

  function toggleAddNewEmployee() {
    setAddingEmployee(!addingEmployee);
    setNewEmployee(newEmployeeDefaults);
  }

  function handleAddEmployeeSave() {
    dispatch(addUser(newEmployee));
    setAddingEmployee(false);
    setNewEmployee(newEmployeeDefaults);
  }

  function handleEmployeeChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setNewEmployee({
      ...newEmployee,
      [name]: value,
    });
  }

  function handlePhoneBlur(phone: string, name: string) {
    setNewEmployee({ ...newEmployee, [name]: formatPhoneNumber(phone) });
  }

  function handleCheckboxChange(name: keyof User) {
    setNewEmployee({
      ...newEmployee,
      [name]: !newEmployee[name],
    });
  }

  function handleSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.target.value);
  }

  if (
    !schedules ||
    [employees.status, locations.status].includes(
      GeneralReducerActionType.LOADING
    )
  ) {
    return null;
  }

  return (
    <ClassNames>
      {({ css }) => {
        const fab = css({
          position: "fixed" as "fixed",
          bottom: "3rem",
          right: "1.5rem",
        });
        const list = css({
          marginTop: 16,
        });
        const icon = css({
          color: "white",
          marginRight: "1rem",
        });
        const input = css({
          width: "100%",
          marginBottom: 16,
        });
        const mrm = css({
          marginRight: "1.5rem",
        });
        const small = css({
          marginRight: "1.5rem",
          width: "5rem",
        });
        const phone = css({
          marginRight: "1.5rem",
          width: "9rem",
        });
        return (
          <div>
            <div>
              <Typography variant="h6" gutterBottom={true}>
                Search
              </Typography>
              <TextField
                placeholder="Search by location or cleaner"
                onChange={handleSearchChange}
                value={search}
                className={input}
              />
            </div>
            <div className={list}>
              <div className="df">
                <Typography variant="h6" gutterBottom={true}>
                  {filteredEmployees.length}
                </Typography>
                <div className="mrs" />
                <Typography variant="h6" gutterBottom={true}>
                  Employees
                </Typography>
              </div>
              <Card>
                <List>
                  {filteredEmployees.length ? (
                    filteredEmployees.map(renderEmployee)
                  ) : (
                    <div className="tac mvm">
                      No employees to display for the selected locations
                    </div>
                  )}
                </List>
              </Card>
            </div>
            <Fab
              onClick={toggleAddNewEmployee}
              className={fab}
              color="primary"
              variant="extended"
            >
              <Add className={icon} />
              Add Employee
            </Fab>
            <Dialog open={addingEmployee}>
              <DialogTitle>Add New Employee</DialogTitle>
              <DialogContent>
                <TextField
                  label="User Name"
                  name="userName"
                  className={mrm}
                  onChange={handleEmployeeChange}
                  value={newEmployee.userName}
                />
                <TextField
                  name="password"
                  label="Password"
                  className={mrm}
                  onChange={handleEmployeeChange}
                  value={newEmployee.password}
                />
                <div className="mvm">
                  <TextField
                    label="First Name"
                    name="firstName"
                    className={mrm}
                    onChange={handleEmployeeChange}
                    value={newEmployee.firstName}
                  />
                  <TextField
                    label="Last Name"
                    name="lastName"
                    className={mrm}
                    onChange={handleEmployeeChange}
                    value={newEmployee.lastName}
                  />
                </div>
                <div className="mbm">
                  <TextField
                    label="Phone"
                    className={phone}
                    name="phone"
                    onChange={handleEmployeeChange}
                    value={newEmployee.phone}
                    fullWidth={true}
                    onBlur={(e) => handlePhoneBlur(e.target.value, "phone")}
                  />
                  <TextField
                    label="Secondary Phone"
                    name="secondaryPhone"
                    className={phone}
                    onChange={handleEmployeeChange}
                    value={newEmployee.secondaryPhone}
                    fullWidth={true}
                    onBlur={(e) =>
                      handlePhoneBlur(e.target.value, "secondaryPhone")
                    }
                  />
                </div>
                <TextField
                  label="Email"
                  name="email"
                  className={mrm}
                  onChange={handleEmployeeChange}
                  value={newEmployee.email}
                />
                <TextField
                  label="Pin"
                  className={small}
                  onChange={handleEmployeeChange}
                  value={newEmployee.pin}
                  fullWidth={true}
                  name="pin"
                />
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newEmployee.admin}
                        onChange={() => handleCheckboxChange("admin")}
                        color="primary"
                      />
                    }
                    label="Admin"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newEmployee.inspector}
                        onChange={() => handleCheckboxChange("inspector")}
                        color="primary"
                      />
                    }
                    label="Inspector"
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <Button color="primary" onClick={toggleAddNewEmployee}>
                  Cancel
                </Button>
                <Button
                  color="primary"
                  onClick={handleAddEmployeeSave}
                  variant="contained"
                >
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      }}
    </ClassNames>
  );
}

export default Employees;

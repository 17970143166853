import { Timestamp } from "firebase/firestore";

export enum ScheduleFrequency {
  Weekly = "weekly",
  BiWeekly = "biweekly",
  SingleWeek = "single-week",
}
export interface Schedule {
  deleted: boolean;
  duration: string;
  employee: string;
  end: string;
  flatRate?: string;
  frequency: ScheduleFrequency;
  id: string;
  location: string;
  newShift: boolean;
  start: string;
  timestamp: Timestamp;
  weekdays: string[];
}

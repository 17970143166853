import {
  doc,
  FieldPath,
  serverTimestamp,
  WhereFilterOp,
  writeBatch,
} from "firebase/firestore";
import { collection, getDocs, query, where } from "firebase/firestore";

import { Schedule } from "types";
import { db } from "../firebaseconfig";

const frequentShiftsService = {
  getByQuery(
    fieldPath: string | FieldPath,
    operation: WhereFilterOp,
    value: any
  ) {
    return getDocs(
      query(
        collection(db, "frequentShifts"),
        where(fieldPath, operation, value)
      )
    );
  },
  async update(schedules: Omit<Schedule, "timestamp">[]) {
    let batch = writeBatch(db);

    for (let i = 0; i < schedules.length; i++) {
      const { id, newShift, deleted, ...thisSchedule } = schedules[i];
      const timestamped = {
        ...thisSchedule,
        timestamp: serverTimestamp(),
      };

      if (newShift) {
        const newId = doc(collection(db, "frequentShifts")).id;
        batch.set(doc(db, "frequentShifts", newId), timestamped);
      } else {
        if (deleted) {
          batch.delete(doc(db, "frequentShifts", id));
        } else {
          // Updated
          batch.set(doc(db, "frequentShifts", id), timestamped);
        }
      }
    }

    return await batch.commit();
  },
};

export default frequentShiftsService;

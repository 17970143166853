import {
  InspectionsActionType,
  LocationActionType,
  MessagesActionType,
  UserActionType,
} from "../actions";
import {
  EventsState,
  InstructionsActionType,
  GlobalActions,
  ShiftsActionType,
  SecurityActionType,
  SuppliesActionType,
  NotificationsActionType,
  AuthenticationActionType,
} from "../types";

export default function eventsReducer(
  state: EventsState = {
    isOpen: false,
    payload: "",
  },
  { type }: GlobalActions
) {
  switch (type) {
    case InstructionsActionType.UPDATE_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Instructions updated successfully.",
      };
    case ShiftsActionType.UPDATE_SHIFTS_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Assigned cleaners updated successfully.",
      };
    case SecurityActionType.UPDATE_SECURITY_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Security updated successfully.",
      };
    case SuppliesActionType.UPDATE_SUPPLIES_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Supplies updated successfully.",
      };
    case UserActionType.UPDATE_USER_LOGIN_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Employee login updated successfully.",
      };
    case InspectionsActionType.UPDATE_INSPECTIONS_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Inspections updated successfully.",
      };
    case UserActionType.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Employee information updated successfully.",
      };
    case ShiftsActionType.UPDATE_SHIFTS_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Unassigned shifts updated successfully.",
      };
    case NotificationsActionType.UPDATE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Notifications updated successfully.",
      };
    case MessagesActionType.ADD_MESSAGE_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Message added successfully.",
      };
    case MessagesActionType.ADD_MESSAGE_ERROR:
      return {
        ...state,
        isOpen: true,
        payload: "There was an error adding your message.",
      };
    case "HIDE_SNACKBAR":
      return {
        ...state,
        isOpen: false,
        payload: "",
      };
    case UserActionType.ADD_USER_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "New employee added successfully.",
      };
    case UserActionType.REMOVE_ALL_NEW_MESSAGES_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "All unread messages have been cleared successfully.",
      };
    case UserActionType.DELETE_USER_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Employee deleted successfully.",
      };
    case AuthenticationActionType.AUTHENTICATE_USER_ERROR:
      return {
        ...state,
        isOpen: true,
        payload: "Incorrect username or password.",
      };
    case LocationActionType.DELETE_LOCATION_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Location deleted successfully.",
      };
    case ShiftsActionType.DOWNLOAD_DATA_EMPTY:
      return {
        ...state,
        isOpen: true,
        payload: "No data exist for the selected date range.",
      };
    case NotificationsActionType.CREATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isOpen: true,
        payload: "Notification added successfully.",
      };
    default:
      return state;
  }
}

import * as React from "react";
import AddCircle from "@mui/icons-material/AddCircle";
import Edit from "@mui/icons-material/Edit";
import Fab from "@mui/material/Fab";
import LocationOn from "@mui/icons-material/LocationOn";
import PhoneIphone from "@mui/icons-material/PhoneIphone";
import Save from "@mui/icons-material/Save";
import SupervisorAccount from "@mui/icons-material/SupervisorAccount";
import TextField from "@mui/material/TextField";
import { CircularProgress, InputLabel, Switch } from "@mui/material";
import { ClassNames } from "@emotion/react";

import { Location } from "../../../types";
import formatPhoneNumber from "utils/formatPhoneNumber";
import { Status } from "types/status";
import { locationService } from "services";

interface LocationEditFormProps {
  location: Location;
}

interface State extends Omit<Location, "id"> {}

function LocationEditForm({ location }: LocationEditFormProps) {
  const [submissionStatus, setSubmissionStatus] = React.useState(Status.Idle);
  const [editing, setEditing] = React.useState(false);
  const [secondaryVisible, setSecondaryVisible] = React.useState(false);
  const [state, setState] = React.useState<State>({
    name: location.name,
    address: {
      city: location.address.city,
      line1: location.address.line1,
      line2: location.address.line2,
      phone: location.address.phone,
      postal: location.address.postal,
      state: location.address.state,
    },
    contact: {
      primary: location.contact.primary,
      secondary: location.contact.secondary,
    },
    supervisor: {
      name: location.supervisor.name,
      phone: location.supervisor.phone,
    },
    assignedId: location.assignedId,
    isGeofenceRequired: location.isGeofenceRequired || false,
  });

  /** General change handlers */
  function handleLocationNameChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    // @ts-ignore
    setState({
      ...state,
      [name]: value,
    });
  }
  function handleAddressChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      address: { ...state.address, [name]: value },
    } as Pick<State, keyof State>);
  }
  function handleSupervisorChange({
    target: { name, value },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      supervisor: { ...state.supervisor, [name]: value },
    } as Pick<State, keyof State>);
  }

  /** Formats phone number for a contact */
  function handlePhoneBlur(
    {
      target: { name, value },
    }: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: "primary" | "secondary"
  ) {
    setState({
      ...state,
      contact: {
        ...state.contact,
        [type]: {
          // @ts-ignore
          ...state.contact[type],
          [name]: formatPhoneNumber(value),
        },
      },
    });
  }

  /** Formats phone number for a contact */
  function handleSupervisorPhoneBlur(phone: string) {
    setState({
      ...state,
      supervisor: {
        ...state.supervisor,
        phone: formatPhoneNumber(phone),
      },
    });
  }

  /** Updates a contact's information */
  function handleContactChange(
    { target: { name, value } }: React.ChangeEvent<HTMLInputElement>,
    type: "secondary" | "primary"
  ) {
    setState({
      ...state,
      contact: {
        ...state.contact,
        [type]: {
          // @ts-ignore
          ...state.contact[type],
          [name]: value,
        },
      },
    });
  }

  /** Handles saving */
  async function handleSave() {
    setSubmissionStatus(Status.Pending);
    try {
      await locationService.update(location.id, state);
      setSubmissionStatus(Status.Success);
      setEditing(false);
    } catch (error) {
      console.log(error);
      setSubmissionStatus(Status.Error);
    }
  }

  /** Shows edit fields */
  function handleEditLocation() {
    if (editing) {
      handleSave();
    } else {
      setEditing(true);
    }
  }

  /** Formats the phone number */
  function handleLocationPhoneBlur({
    target: { value },
  }: React.FocusEvent<HTMLInputElement>) {
    setState({
      ...state,
      address: {
        // @ts-ignore
        ...state.address,
        phone: formatPhoneNumber(value),
      },
    });
  }

  /** Handles geofence toggle change */
  function handleGeofenceChange({
    target: { checked },
  }: React.ChangeEvent<HTMLInputElement>) {
    setState({
      ...state,
      isGeofenceRequired: checked,
    });
  }

  const { address, contact, supervisor } = state;
  return (
    <ClassNames>
      {({ css }) => (
        <div className="pr">
          <div className="location-edit-form">
            <div className={`${editing ? "visible" : "hidden"}`}>
              <div className="pvm phl bgwhite">
                <div className="df aic separate pbs mbs">
                  <AddCircle color="action" className="mrs" />
                  <div>
                    <TextField
                      label="Location Name"
                      name="name"
                      onChange={handleLocationNameChange}
                      value={state.name}
                      className="mrm"
                    />
                  </div>
                  <div>
                    <InputLabel>Geofence</InputLabel>
                    <Switch
                      name="isGeofenceRequired"
                      onChange={handleGeofenceChange}
                      checked={state.isGeofenceRequired}
                      className="mrm __switch"
                      classes={{
                        switchBase: css({ height: "32px" }),
                      }}
                    />
                  </div>
                </div>
                <div className="df aic">
                  <LocationOn color="action" className="mrs" />
                  <div>
                    <TextField
                      label="Address Line 1"
                      name="line1"
                      className="mrm"
                      onChange={handleAddressChange}
                      value={address.line1}
                    />
                  </div>
                  <div>
                    <TextField
                      label="Address Line 2"
                      name="line2"
                      className="mrm"
                      onChange={handleAddressChange}
                      value={address.line2}
                    />
                  </div>
                </div>
                <div className="df aic separate pvs mbs ml40">
                  <div>
                    <TextField
                      label="City"
                      className="mrm iwm"
                      name="city"
                      onChange={handleAddressChange}
                      value={address.city}
                    />
                  </div>
                  <div>
                    <TextField
                      label="State"
                      className="mrm iws"
                      name="state"
                      onChange={handleAddressChange}
                      value={address.state}
                    />
                  </div>
                  <div>
                    <TextField
                      label="Zip Code"
                      className="mrm iwm"
                      name="postal"
                      onChange={handleAddressChange}
                      value={address.postal}
                    />
                  </div>
                  <div>
                    <TextField
                      label="Phone"
                      className="mrm"
                      name="phone"
                      onBlur={handleLocationPhoneBlur}
                      onChange={handleAddressChange}
                      value={address.phone}
                    />
                  </div>
                </div>
                <div className="df aic separate pbs mbs">
                  <SupervisorAccount color="action" className="mrs" />
                  <div>
                    <TextField
                      className="mrm"
                      label="Supervisor"
                      value={supervisor.name}
                      name="name"
                      onChange={handleSupervisorChange}
                    />
                  </div>
                  <TextField
                    label="Contact Phone"
                    value={supervisor.phone}
                    name="phone"
                    onChange={handleSupervisorChange}
                    onBlur={(e) => handleSupervisorPhoneBlur(e.target.value)}
                  />
                </div>
                <div className="df aic separate pbs mbs">
                  <PhoneIphone color="action" className="mrs" />
                  <div>
                    <TextField
                      label="Contact Name (Primary)"
                      value={contact.primary!.name}
                      className="mrm"
                      name="name"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleContactChange(e, "primary")
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      label="Contact Phone"
                      value={contact.primary!.phone}
                      className="mrm"
                      name="phone"
                      onBlur={(e) => handlePhoneBlur(e, "primary")}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleContactChange(e, "primary")
                      }
                    />
                  </div>
                  <div>
                    <TextField
                      label="Contact Email"
                      value={contact.primary!.email}
                      name="email"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleContactChange(e, "primary")
                      }
                    />
                  </div>
                </div>
                {(contact.secondary || secondaryVisible) && (
                  <div className="df aic pbs">
                    <PhoneIphone color="action" className="mrs" />
                    <div>
                      <TextField
                        label="Contact Name (Secondary)"
                        value={contact.secondary!.name}
                        name="name"
                        className="mrm"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleContactChange(e, "secondary")
                        }
                      />
                    </div>
                    <div>
                      <TextField
                        label="Contact Phone"
                        value={contact.secondary!.phone}
                        name="phone"
                        className="mrm"
                        onBlur={(e) => handlePhoneBlur(e, "secondary")}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleContactChange(e, "secondary")
                        }
                      />
                    </div>
                    <div>
                      <TextField
                        label="Contact Email"
                        value={contact.secondary!.email}
                        name="email"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleContactChange(e, "secondary")
                        }
                      />
                    </div>
                  </div>
                )}
                {!contact.secondary && !secondaryVisible && (
                  <div
                    className="df aic mvm cp"
                    onClick={() => setSecondaryVisible(true)}
                  >
                    <AddCircle color="primary" />
                    Add Contact
                  </div>
                )}
              </div>
            </div>
          </div>
          <Fab
            onClick={handleEditLocation}
            color="primary"
            style={{
              position: "absolute",
              right: "5rem",
              marginTop: "-30px",
              zIndex: 1100,
            }}
          >
            {editing && submissionStatus !== Status.Pending && <Save />}
            {!editing && submissionStatus !== Status.Pending && <Edit />}
            {submissionStatus === Status.Pending ? (
              <CircularProgress color="secondary" size={16} />
            ) : null}
          </Fab>
        </div>
      )}
    </ClassNames>
  );
}

export default LocationEditForm;
